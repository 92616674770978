import { Button, message, Spin } from "antd";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import NotFoundData from "../components/NotFoundData";
import { baseUrl } from "../services/services.profile";
import { useUserContext } from "../contexts/userContext";
import CreateNewKybLinkDrawer from "../components/shared/Drawer/CreateNewKybLinkDrawer";
import AiViewerDrawer from "../components/shared/Drawer/AiViewerDrawer/aiViewerDrawer";
import moment from "moment";

const buttonDataList = [
  {
    name: "Currently Onboarding",
    key: "all",
  },
  { name: "Pending Verification", key: "internalChecking" },
  {
    name: "Verification Update Requested",
    key: "crossGridPending",
  },
  {
    name: "Annual Confirmation Update Verification",
    key: "isAnnualVerificationRequest",
  },
];

const crossGridRequestedFormate = (dateString) => {
  return moment(dateString).format("ddd, DD MMM YYYY");
};

const ConnectionsOnboarding = () => {
  const { user, userData, RoleManage } = useUserContext();
  const isAdmin123 = userData?._id === "652943c69df2a7301b23c9ab";
  const [addProfile, setAddProfile] = useState("");
  const removeAccess = [RoleManage?.MLRO, RoleManage?.ADMIN];
  const [selectedButton, setSelectedButton] = useState("all");
  const [currentlyOnboarding, setCurrentlyOnboarding] = useState([]);
  const [pendingVerification, setPendingVerification] = useState([]);
  const [updateRequested, setUpdateRequested] = useState([]);
  const [annualVerificationRequest, setAnnualVerificationRequest] = useState(
    []
  );
  const [openAiGrid, setOpenAiGrid] = useState(false);
  const [singleRecordData, setSingleRecordData] = useState({
    tokenId: "",
    companyName: "",
  });
  const [createNewKybLinkDrawerOpen, setCreateNewKybLinkDrawerOpen] =
    useState(false);

  const [loading, setLoading] = useState({
    all: false,
    internalChecking: false,
    crossGridPending: false,
    isAnnualVerificationRequest: false,
  });

  const getOnBoardingApi = () => {
    buttonDataList?.forEach((item) => {
      setLoading((prev) => ({ ...prev, [item?.key]: true }));
      const payload = {
        filter: {
          [item?.key]: true,
        },
      };

      const apiCallUrl = axios.post(
        baseUrl + `/api/onboarding/all-list`,
        payload
      );

      apiCallUrl.then((res) => {
        const array = Array.isArray(res?.data?.data) ? res?.data?.data : [];
        item?.key === "all"
          ? setCurrentlyOnboarding(array)
          : item?.key === "internalChecking"
          ? setPendingVerification(array)
          : item?.key === "crossGridPending"
          ? setUpdateRequested(array)
          : item?.key === "isAnnualVerificationRequest" &&
            setAnnualVerificationRequest(array);

        setLoading((prev) => ({ ...prev, [item?.key]: false }));
      });
    });
  };

  useEffect(() => {
    getOnBoardingApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBoardingLink = (item) => {
    const onBoardingbaseUrl = "https://clients.onboarding.ipass.id";
    window.open(onBoardingbaseUrl + "/#/" + item?.token_id, "_blank");
  };

  const removeOnboarding = async (item) => {
    await axios
      .post(baseUrl + `/user/onboarding/delete`, {
        tokenId: item?.token_id,
      })
      .then(() => {
        message.success("Onboarding remove Successfully");
        getOnBoardingApi();
      })
      .catch(() => {
        message.error("Invalid Onboarding request");
      });
  };

  const removeAnnualVerificationOnboarding = async (item) => {
    await axios
      .post(baseUrl + `/link/reset-annual-verification`, {
        token_id: item?.token_id,
      })
      .then(() => {
        message.success("Annual Verification Onboarding remove Successfully");
        getOnBoardingApi();
      })
      .catch(() => {
        message.error("Invalid Annual Verification Onboarding request");
      });
  };

  const sendReminderApi = async (item) => {
    await axios
      .post(baseUrl + `/user/onboarding/resendemail`, {
        tokenId: item?.token_id,
      })
      .then(() => {
        message.success("Email has been sent successfully");
        getOnBoardingApi();
      })
      .catch(() => {
        message.error("Invalid email");
      });
  };

  const renderOnBoardingList = (list, isLoading) => {
    return isLoading ? (
      <Spin className="w-full text-center mt-10" />
    ) : (
      <>
        {list?.length === 0 ? (
          <NotFoundData
            key="no data"
            message="No onboardings are currently taking place"
          />
        ) : (
          list?.map((item, index) => (
            <React.Fragment key={index}>
              <div
                className="w-full mb-6 flex-col sm:flex-row bg-[#f0f6fd] rounded-md p-4 flex justify-between gap-4"
                key={index}
              >
                <div className="flex flex-col text-[15px] justify-center items-start p-3">
                  <p className="font-bold mb-2">
                    {" "}
                    {item?.user_info?.email}{" "}
                    {item?.isSelfOnboard ? (
                      <>
                        {item?.user_info?.self_fname ||
                        item?.user_info?.self_lname
                          ? " - "
                          : ""}
                        {item?.user_info?.self_fname}
                        {item?.user_info?.self_fname &&
                          item?.user_info?.self_lname &&
                          " "}
                        {item?.user_info?.self_lname}
                      </>
                    ) : (
                      <>
                        {item?.user_info?.fname || item?.user_info?.lname
                          ? " - "
                          : ""}
                        {item?.user_info?.fname}
                        {item?.user_info?.fname &&
                          item?.user_info?.lname &&
                          " "}
                        {item?.user_info?.lname}
                      </>
                    )}
                  </p>

                  <p className="flex gap-1 mb-1">
                    {item?.user_info?.utype && (
                      <span className="font-medium">
                        {item?.user_info?.utype === "PATRON"
                          ? `Natural Person ${
                              item?.isAnnualVerificationRequest
                                ? "Annual CDD Update"
                                : "Onboarding"
                            }`
                          : item?.user_info?.utype === "BUSINESS" &&
                            `Legal Entity ${
                              item?.isAnnualVerificationRequest
                                ? "Annual CDD Update"
                                : "Onboarding"
                            }`}
                      </span>
                    )}
                    {!item?.isAnnualVerificationRequest && (
                      <span className="font-medium">
                        (
                        {item?.isSelfOnboard
                          ? "Self Upload Onboarding"
                          : "Automated Onboarding"}
                        )
                      </span>
                    )}
                  </p>

                  {item?.isInternalCheckingPending && (
                    <div className="flex items-center gap-1 mb-2">
                      <p>Assigned to:</p>
                      <p className="font-semibold">Customer</p>
                    </div>
                  )}

                  <p
                    className="underline text-primary hover:cursor-pointer break-all mb-1"
                    onClick={() => {
                      onBoardingLink(item);
                    }}
                  >
                    {`Your iPass ID KYC onboarding link:https://clients.onboarding.ipass.id/#/${item?.token_id}`}
                    {/* Onboarding link */}
                  </p>
                  <div className="flex gap-1">
                    <p>
                      {item?.crossGridPending
                        ? "Cross Reference Grid Creation Requested on:"
                        : "requested on:"}
                    </p>
                    <span className="font-semibold">
                      {item?.crossGridPending
                        ? crossGridRequestedFormate(item?.crossGridRequestedOn)
                        : item?.receivedInfo?.respResult?.date_created
                            ?.toString()
                            .slice(0, 16)}
                    </span>
                  </div>
                  <div className="flex gap-1 mb-2">
                    <p>Status:</p>
                    <span className="font-semibold">{item?.status}</span>
                  </div>

                  <>
                    {"poiVerifyToken" in item?.userCertificateData && (
                      <div className="flex gap-1">
                        {item?.userCertificateData?.poiVerifyToken ? (
                          <span className="font-semibold text-danger">
                            Company information update requested
                          </span>
                        ) : (
                          <span className="font-semibold text-[#70ad47]">
                            Company information update received
                          </span>
                        )}
                      </div>
                    )}
                    {"uboVerifyToken" in item?.userCertificateData && (
                      <div className="flex gap-1 mb-1">
                        {item?.userCertificateData?.uboVerifyToken ? (
                          <span className="font-semibold text-danger">
                            UBO information update requested
                          </span>
                        ) : (
                          <span className="font-semibold text-[#70ad47]">
                            UBO information update received
                          </span>
                        )}
                      </div>
                    )}

                    {"cddVerifyToken" in item?.userCertificateData && (
                      <div className="flex gap-1 mb-1">
                        {item?.userCertificateData?.cddVerifyToken ? (
                          <span className="font-semibold text-danger">
                            New CDD self-declaration information requested
                          </span>
                        ) : (
                          <span className="font-semibold text-[#70ad47]">
                            New CDD self-declaration information received
                          </span>
                        )}
                      </div>
                    )}
                  </>
                </div>

                <div className="flex flex-col justify-center gap-3 items-center text-[15px] px-5">
                  {/* Send ai grid button  */}
                  {(item?.isInternalCheckingPending ||
                    item?.crossGridPending ||
                    item?.isAnnualVerificationRequest) && (
                    <button
                      className="border bg-primary text-white px-3 whitespace-nowrap h-9 w-full sm:w-[163px] rounded-lg"
                      onClick={() => {
                        setOpenAiGrid(true);
                        setSingleRecordData({
                          tokenId: item?.token_id,
                          companyName: item?.user_info?.company?.name,
                        });
                      }}
                    >
                      AI Grid
                    </button>
                  )}

                  {/* Send reminder button  */}
                  {!item?.isInternalCheckingPending &&
                    !item?.isAnnualVerificationRequest && (
                      <button
                        className="border border-[#86c1f4] text-primary px-3 whitespace-nowrap h-9 w-full sm:w-[163px] rounded-lg"
                        onClick={() => sendReminderApi(item)}
                      >
                        Send reminder
                      </button>
                    )}

                  {/* Remove annual verification onboarding button  */}
                  {item?.isAnnualVerificationRequest && isAdmin123 && (
                    <button
                      className="border border-[#86c1f4] text-primary px-3 whitespace-nowrap h-9 w-full sm:w-[163px] rounded-lg"
                      onClick={() => removeAnnualVerificationOnboarding(item)}
                    >
                      Remove
                    </button>
                  )}

                  {/* Remove onboarding button  */}
                  {removeAccess.includes(user) &&
                    !item?.crossGridPending &&
                    !item?.isAnnualVerificationRequest && (
                      <button
                        className="border border-[#86c1f4] text-primary px-3 whitespace-nowrap h-9 w-full sm:w-[163px] rounded-lg"
                        onClick={() => removeOnboarding(item)}
                      >
                        Remove onboarding
                      </button>
                    )}
                </div>
              </div>
            </React.Fragment>
          ))
        )}
      </>
    );
  };

  return (
    <>
      <>
        <div className="flex flex-wrap mb-4 justify-between gap-4">
          <div className="flex items-center flex-wrap gap-4">
            {buttonDataList?.map((item, key) => (
              <div key={key}>
                <Button
                  onClick={() => setSelectedButton(item?.key)}
                  className={`text-xs flex !border-primary ${
                    item?.key === selectedButton
                      ? "!bg-white !text-primary"
                      : "!bg-primary !text-white"
                  } border-2 whitespace-nowrap  font-semibold tracking-wide  px-4 h-8 rounded-lg`}
                >
                  {item?.name}
                </Button>
              </div>
            ))}
          </div>
          <div className="flex justify-end items-center gap-3 ml-auto">
            <Button
              type="primary"
              className="text-xs border-primary flex cursor-pointer border-2 whitespace-nowrap font-semibold tracking-wide !text-white bg-primary px-4 h-8 rounded-lg"
              onClick={() => {
                setAddProfile("kyc");
                setCreateNewKybLinkDrawerOpen(true);
              }}
            >
              Add KYC Profile
            </Button>
            <Button
              type="primary"
              className="text-xs border-primary flex cursor-pointer border-2 whitespace-nowrap font-semibold tracking-wide !text-white bg-primary px-4 h-8 rounded-lg"
              onClick={() => {
                setAddProfile("kyb");
                setCreateNewKybLinkDrawerOpen(true);
              }}
            >
              Add KYB Profile
            </Button>
          </div>
        </div>

        {selectedButton === "all"
          ? renderOnBoardingList(currentlyOnboarding, loading.all)
          : selectedButton === "internalChecking"
          ? renderOnBoardingList(pendingVerification, loading.internalChecking)
          : selectedButton === "crossGridPending"
          ? renderOnBoardingList(updateRequested, loading.crossGridPending)
          : selectedButton === "isAnnualVerificationRequest" &&
            renderOnBoardingList(
              annualVerificationRequest,
              loading.isAnnualVerificationRequest
            )}
      </>

      {createNewKybLinkDrawerOpen && (
        <CreateNewKybLinkDrawer
          createNewKybLinkDrawerOpen={createNewKybLinkDrawerOpen}
          setCreateNewKybLinkDrawerOpen={setCreateNewKybLinkDrawerOpen}
          getOnBoardingApi={getOnBoardingApi}
          type={addProfile}
        />
      )}

      {openAiGrid && (
        <AiViewerDrawer
          aiViewerDrawerOpen={openAiGrid}
          aiViewerDrawerClose={setOpenAiGrid}
          tokenId={singleRecordData?.tokenId}
          companyName={singleRecordData?.companyName}
          getOnBoardingApi={getOnBoardingApi}
          customerUi={false}
        />
      )}
    </>
  );
};

export default ConnectionsOnboarding;
